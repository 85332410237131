/*---------------------------------------------------
      Material UI: default theme
---------------------------------------------------*/

import tinycolor from "tinycolor2";

const primary = "#003399";
const secondary = "#00549a";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";

const lightenRate = 7.5;
const darkenRate = 15;

export default {
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF"
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString()
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString()
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString()
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9"
    },
    background: {
      default: '#fafafa',//"#F6F7FF",
      light: "#F3F5FF",
      whight: '#FFFFFF',
      paper: "#FFFFFF"
    }
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
  },
  overrides: {
    /* Material UI*/
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A"
      }
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      }
    },
    MuiListItem: {
      button: {
        '&:hover, &:focus': {
          backgroundColor: '#F3F5FF',
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white"
      }
    },
    MuiTableRow: {
      root: {
        height: 16,
      }
    },
    MuiTableCell: {
      root: {
        paddingTop: '0px',
        paddingRight: '0px',
        paddingBottom: '0px',
        paddingLeft: '16px',
        borderBottom: '1px solid rgba(224, 224, 224, .5)',
      },
      head: {
        fontSize: '0.8rem',
      },
      body: {
        fontSize: '0.8rem',
      }
    },
    MuiButton: {
      root: {
        padding: '0px'
      },
      text: {
        padding: '0px'
      }
    },
    MuiDialog: {
      paper:{
      '@media (max-width: 575.98px)': {
        margin: '0px'
        }
      },
      paperFullWidth: {
        '@media (max-width: 575.98px)': {
          width: 'calc(100% - 34px)'
        }
      }
    },
    MuiDialogContent: {
      root: {
        '&:first-child': {
          '@media (max-width: 575.98px)': {
            paddingTop: '10px'
          }
        }
      }
    },
    MuiTab:{
      root:{
        '@media (min-width: 600px)' :{
          minWidth: '10px',
        }
      },
      labelIcon:{
        '@media (max-width: 575.98px)': {
          minHeight: '56px',
          paddingTop: '8px'
        },
      '& .MuiTab-wrapper > *:first-child': {
        '@media (max-width: 575.98px)': {
          marginBottom: '0px'
        }
      }
    },

    },
    /* MUI Datatables */
    MUIDataTableToolbar: {
      titleText: {
        fontSize: '1.042rem', 
        color: primary,
        textAlign: "left",
      },
      filterPaper :{
        maxWidth: '70%'
      }
    },
    MuiIconButton: {
      root:{
        paddingTop: '0px',
        paddingRight: '3px',
        paddingBottom: '0px',
        paddingLeft: '0px',
      }
    },
    MUIDataTableFilterList: {
      root: {
        fontSize: '1.142rem', 
        margin: '0px 16px 0px 16px'
      },
      chip: {
        margin: '0px 8px 0px 0px',
      },
    },
    MUIDataTableBodyCell: {
      root: {
        '@media (max-width:575.98px)': {
          paddingTop: '3px',
          paddingLeft: '0px',
          borderBottom: '0px',
        }
      },
      cellStackedSmall: {
        '@media (max-width:575.98px)': {
          width: '35%'
        },
        '@media (max-width: 767.98px)':{
          width: '35%'
        }
      },
      stackedCommon: {
        '@media (max-width:575.98px)': {
          height: '28px',
          fontSize: '14px',
          whiteSpace: 'nowrap',
        },
      },
    }
  }
}
