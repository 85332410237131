/* react */
import React from "react";

/* material-ui/style */
import { withStyles } from "@material-ui/core/styles";

/* material-ui/core */
//import Grid from '@material-ui/core/Grid';
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MapIcon from '@material-ui/icons/Map';
import Paper from '@material-ui/core/Paper';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';

/* leaflet */
import {
  LayerGroup,
  Map,
  Marker,
  TileLayer,
  Popup,
  LayersControl,
} from 'react-leaflet'
import "leaflet-polylinedecorator";
import "react-leaflet-markercluster/dist/styles.min.css";
import MarkerCluster from "../Leaflet/MarkerCluster";
import { iconShop } from '../Leaflet/LeafletIcons';

/* etc */
import moment from 'moment';

const drawerWidth = 240;

const defaultToolbarStyles = theme => ({
  iconButton: {},
  root:{
    padding: '0px'
  },
  dialog: {
    padding: '10px'
  },
  paperMap:{
    width: '100%',
    height: '600px',
    '@media (max-height:500px) and (max-width:830px)': {
      height: '260px'
    },
    '@media (max-height:700px)': {
      height: '500px'
    },
    '@media (max-height:600px)': {
      height: '400px'
    },
    '@media (max-width:575.99px)': {
      height: '380px',
    },
  },
  button: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 999,
  },
  closeButton: {
    zIndex: 999,
    left: 'auto',
    right: 8,
    top: 50,
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      background: "#ebebeb",
    },
  },
  iconClose:{
    color: theme.palette.text.secondary,
  }
});

class CustomToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MakerList: [],
      ClusterMakerList: [],
      ShopMakerListArray: [],
      MultiPolyLineList: [],
      MultiMakerList: [],
      MapOpen: false,
      centerPosition: [36.258595, 137.6850225],
      zoomValue: '5'
    }
  }

  handleClick = () => {
    this.setState({ 'MapOpen': true });

    this.props.windowSize.width < 830 ? this.setState({ 'zoomValue': 4 }) : this.setState({ 'zoomValue': 5 })

    const ClusterMakerListArray = [];
    const ShopMakerListArrayAll = [];

    /*  remove duplicated shop >>> make list for shop display */
    const shopList = this.props.data.filter(function (v1, i1, a1) {
      return (a1.findIndex(function (v2) {
        return (v1.name_shop === v2.name_shop)
      }) === i1);
    });

    for (var j in shopList) {
      ShopMakerListArrayAll.push({
        key: shopList[j].timestamp,
        position: [
          Number(shopList[j].location_shop.lat),
          Number(shopList[j].location_shop.lng)
        ],
        children: shopList[j].name_shop,
        icon: iconShop
      })
      this.setState({ 'ShopMakerListArray': ShopMakerListArrayAll });
    }

    /* make list for device display */
    for (var i in this.props.data) {
      if (this.props.data[i].location_device.lng === 0 || this.props.data[i].location_device.lat === 0) {
      } else {
        ClusterMakerListArray.push({
          position: {
            lng: Number(this.props.data[i].location_device.lng),
            lat: Number(this.props.data[i].location_device.lat)
          },
          text: '<center>' + this.props.data[i].num_device + '</br>' + this.props.data[i].name_label + '</br>' + this.props.data[i].name_shop + '</br>' + moment(this.props.data[i].timestamp).format('YYYY/MM/DD hh:mm:ss') + '</center>',
        })
        this.setState({ 'ClusterMakerList': ClusterMakerListArray });
      }
    }
  };

  handleClose = () => {
    this.setState({ 'MapOpen': false });
    this.setState({ 'ClusterMakerList': [] })
    this.setState({ 'ShopMakerListArray': [] })
  };

  render() {
    const { classes } = this.props;
    const { BaseLayer, Overlay } = LayersControl;

    const MyMarkersList = ({ markers }) => {
      const items = markers.map(({ key, ...props }) => (
        <MyPopupMarker key={key} {...props} />
      ))
      return <div style={{ display: 'none' }}>{items}</div>
    }

    const MyPopupMarker = ({ position, children, icon }) => (
      <Marker position={position} icon={icon}>
        <Popup>
          {children}
        </Popup>
      </Marker>
    )

    return (
      <React.Fragment>
        <Tooltip title={"MAP"}>
          <IconButton className={classes.iconButton} onClick={this.handleClick}>
            <MapIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>

        <Dialog
          open={this.state.MapOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          maxWidth='lg'
          fullWidth={true}
        >
{/*
          <Fab color="primary" aria-label="add" className={classes.button} size='small' onClick={this.handleClose}>
            <CloseIcon />
          </Fab>
*/}
          <DialogContent className={classes.dialog}>
            <Paper className={classes.paperMap}>
              {/*<ContentMap>*/}
                <Map
                  className="markercluster-map"
                  center={this.state.centerPosition}
                  zoom={this.state.zoomValue}
                  maxZoom={18}
                >
{/*
                <IconButton onClick={this.handleClose} color="primary" className={classes.closeButton}  size="large">
                  <CancelIcon fontSize='large'/>
                </IconButton>
                <Button variant="contained" color="primary" size="small" className={classes.closeButton} onClick={this.handleClose}>
                  閉じる
                </Button>
*/}
                <Fab color="primary" aria-label="add" className={classes.closeButton} size='small' onClick={this.handleClose}>
                  <CloseIcon className={classes.iconClose}/>
                </Fab>
                  <LayersControl position="topright">
                    <BaseLayer name="OpenStreetMap.BlackAndWhite">
                      <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                      />
                    </BaseLayer>
                    <BaseLayer checked name="OpenStreetMap.Mapnik">
                      <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                    </BaseLayer>
                    <MarkerCluster markers={this.state.ClusterMakerList} />
                    <Overlay checked name="店舗" >
                      <LayerGroup >
                        <MyMarkersList markers={this.state.ShopMakerListArray} />
                      </LayerGroup >
                    </Overlay>
                  </LayersControl>
                </Map>
              {/*</ContentMap>*/}
              </Paper>
{/*
            <Button onClick={this.handleClose} color="primary" autoFocus className={classes.closeButton}>
              CLOSE
            </Button>
*/}
          </DialogContent>
        </Dialog>

      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "customToolbar" })(
  CustomToolbar
);
